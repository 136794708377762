<template>
  <div
    class="d-lg-flex"
  >
    <b-card
      class="flex-grow-1 mr-1 "
    >
      <b-card-title>
        Experiments
      </b-card-title>
      <experiment-editor />
      <hr class="mt-0">
      <experiment-variants-editor-layout />
    </b-card>
    <b-card
      no-body
      class="d-flex p-2 experiment-options-card"
    >
      <b-button
        variant="outline-primary"
        @click="saveAndReturn"
      >
        Save
      </b-button>
      <b-button
        variant="danger"
        class="mt-1"
        @click="cancel"
      >
        Cancel
      </b-button>
      <b-button
        v-if="isDeleteable"
        variant="outline-danger"
        class="mt-1"
        @click="deleteExperiment"
      >
        Delete
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ON_EXPERIMENT_EDITION } from '@/libs/eventBusConstants'
import ExperimentEditor from './ExperimentEditor.vue'
import ExperimentVariantsEditorLayout from './ExperimentVariantsEditorLayout.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    ExperimentEditor,
    ExperimentVariantsEditorLayout,
  },
  data() {
    return {
      validationsCb: [],
    }
  },
  computed: {
    ...mapGetters('experimentEditor', ['isSavable', 'isDeleteable']),
  },
  created() {
    this.$eventBus.$on(ON_EXPERIMENT_EDITION, ({ validate }) => {
      this.validationsCb.push(validate)
    })
    // Load experiment if path includes an identifier
    const { id } = this.$route.params
    if (id) {
      this.get(id)
    } else {
      this.reset()
    }
  },
  beforeDestroy() {
    this.$eventBus.$off(ON_EXPERIMENT_EDITION)
  },
  methods: {
    ...mapActions('experimentEditor', {
      save: 'saveExperiment',
      get: 'fetchExperiment',
      reset: 'resetEditor',
      delete: 'deleteExperiment',
    }),
    saveAndReturn() {
      // Check if there is an effort to save
      if (!this.isSavable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'All variants need to be saved',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }

      Promise.all(this.validationsCb.map(cb => cb())).then(() => {
        this.save()
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Your work has been saved',
              html: `Use your experiments variant in your campaings by clicking on 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
              </svg>`,
              showConfirmButton: false,
              timer: 2500,
              buttonsStyling: false,
            })

            // this.$router.push({ name: 'acquisition' })
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${err}`,
                icon: 'InfoIcon',
                variant: 'warning',
              },
            })
          })
      }).catch(() => {})
      return true
    },
    cancel() {
      this.$swal({
        title: 'Discard changes',
        text: 'Any change will be discarded',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.reset()
          this.$router.push({ name: 'acquisition' })
        }
      })
    },
    deleteExperiment() {
      this.$swal({
        title: 'Delete this experiment',
        text: 'This action can not be undone',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.delete()
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your experiment has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router.push({ name: 'acquisition' })
              })
          }
        }).catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
.experiment-options-card {
  min-width: 17em;
}
</style>
