var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"detailForm"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"exp-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"For what reason are my users coming to our website? E.g. Product launch US October 2021, Black Friday 2022, Summer 2022 mailing campaign.","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exp-name","placeholder":"The name of marketing experiment strategic","debounce":"400","value":_vm.name,"state":errors.length > 0 ? false : null},on:{"input":_vm.setName}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"exp-description","label":"Description"}},[_c('b-form-textarea',{attrs:{"id":"exp-description","placeholder":"Description","debounce":"400","rows":"4","value":_vm.description},on:{"input":_vm.setDescription}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-secondary"}},[_c('feather-icon',{attrs:{"icon":"AwardIcon"}})],1),_c('b-form-group',{staticClass:"flex-grow-1"},[_c('label',[_vm._v("Goal *")]),_c('validation-provider',{attrs:{"name":"Goal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('v-select',{class:_vm.verifyGoal(errors, pristine),attrs:{"options":_vm.goals,"value":_vm.idgoal,"label":"name","reduce":function (goal) { return goal.idgoal; }},on:{"input":_vm.setGoal}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-success"}},[_c('feather-icon',{attrs:{"icon":"TrendingUpIcon"}})],1),_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label-for":"exp-conversions","label":"Expected conversions"}},[_c('validation-provider',{attrs:{"name":"Conversions","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exp-conversions","placeholder":"Expected conversions","debounce":"400","type":"number","value":_vm.expectedConversions,"state":errors.length > 0 ? false : null},on:{"input":_vm.setExpectedConversions}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-info"}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon"}})],1),_c('b-form-group',{staticClass:"flex-grow-1",attrs:{"label-for":"exp-investment","label":"Total experiment cost"}},[_c('b-form-input',{attrs:{"id":"exp-investment","placeholder":"Total experiment cost","disabled":"","value":_vm.getEditorCost}})],1)],1)])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"exp-start","label":"Experiment start date *"}},[_c('validation-provider',{attrs:{"name":"Start date","vid":"startDate","rules":"required|regex:^-?[\\d]+\\-[0-1]\\d\\-[0-3]\\d$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"id":"exp-start","autocomplete":"off","placeholder":"YYYY-MM-DD","debounce":"800","value":_vm._f("formatDate")(_vm.startDate),"state":errors.length > 0 ? false : null},on:{"input":_vm.setStartDate}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"value":_vm.startDate,"right":"","button-only":"","locale":"en-US","size":"sm"},on:{"input":_vm.setStartDate}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"exp-end","label":"Experiment end date *"}},[_c('validation-provider',{attrs:{"name":"end date","rules":"required|regex:^-?[\\d]+\\-[0-1]\\d\\-[0-3]\\d$|after:@startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"id":"exp-end","autocomplete":"off","placeholder":"YYYY-MM-DD","debounce":"800","value":_vm._f("formatDate")(_vm.endDate),"state":errors.length > 0 ? false : null},on:{"input":_vm.setEndDate}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"value":_vm.endDate,"right":"","button-only":"","locale":"en-US","size":"sm"},on:{"input":_vm.setEndDate}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }