var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"flex-grow-1 d-flex border rounded bg-light "},[_c('validation-observer',{ref:"effortForm",staticClass:"pt-2",attrs:{"slim":""}},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.isNewVariant? "New": "Update")+" experiment variant ")])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-name")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Variant name * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"Marketing strategic variant name. E.g. Black Friday 2022 ADS, Black Friday 2022 Facebook.","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":{ required: true, notIn: { values: _vm.getNames(_vm.index) } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-name"),"placeholder":"Name","debounce":"400","value":_vm.effort.name,"autocomplete":"off","state":errors.length > 0 ? false : null},on:{"input":_vm.setName}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-type")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Channel * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"How are my users coming to our website? The communication channel this link will be placed.","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-type"),"list":"effort-types-datalist","placeholder":"Communication channel category","debounce":"400","value":_vm.effort.type,"state":errors.length > 0 ? false : null},on:{"input":_vm.setType}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-cost")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Amount to spend * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"How much money will be spent on this marketing strategy variant?","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Cost","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-cost"),"type":"number","placeholder":"Amount to spend","value":_vm.effort.cost,"state":errors.length > 0 ? false : null},on:{"input":_vm.setCost}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-source")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Users source * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"Where are the users coming from? E.g. Facebook, Google, Abandoned Cart Users, BBC Referals, etc.","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-source"),"list":"effort-sources-datalist","placeholder":"Source","debounce":"500","value":_vm.effort.source,"state":errors.length > 0 ? false : null},on:{"input":_vm.setSource}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-content")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Content (Optional) "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"Helps you differentiate between ads or posts on the same platform. Comes in handy for A/B testing images or ad copy.","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-content"),"list":"effort-contents-datalist","placeholder":"Content","debounce":"500","value":_vm.effort.content},on:{"input":function (content) { return _vm.updateEffort({index: _vm.index, payload: { content: content }}); }}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":("effort-" + _vm.index + "-website")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Website to track * "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"The arrival page of the users. E.g. https://www.mycompany.com/blackfriday2020","icon":"HelpCircleIcon"}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Website","rules":"required|urlValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"Link2Icon"}})],1),_c('b-form-input',{attrs:{"id":("effort-" + _vm.index + "-website"),"disabled":!_vm.isNewVariant,"placeholder":"Website","debounce":"500","value":_vm.effort.url,"state":errors.length > 0 ? false : null},on:{"input":_vm.setUrl}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right mb-1"},[(!_vm.isNewVariant)?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.cancelEditEffort(_vm.index)}}},[_vm._v(" Cancel ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.stage}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column border-left py-50 px-25"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-1 btn-remove-variant",attrs:{"size":"24","icon":"XIcon","role":"button","title":"Delete effort"},on:{"click":function($event){return _vm.confirmRemove(_vm.index)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }