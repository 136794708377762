<template>
  <validation-observer
    ref="detailForm"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group label-for="exp-name">
          <template #label>
            Name *
            <feather-icon
              v-b-tooltip.hover
              title="For what reason are my users coming to our website? E.g. Product launch US October 2021, Black Friday 2022, Summer 2022 mailing campaign."
              class="text-primary"
              icon="HelpCircleIcon"
            />
          </template>
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              id="exp-name"
              placeholder="The name of marketing experiment strategic"
              debounce="400"
              :value="name"
              :state="errors.length > 0 ? false : null"
              @input="setName"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="exp-description"
          label="Description"
        >
          <b-form-textarea
            id="exp-description"
            placeholder="Description"
            debounce="400"
            rows="4"
            :value="description"
            @input="setDescription"
          />
        </b-form-group>

      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            variant="light-secondary"
            class="mr-1"
          >
            <feather-icon icon="AwardIcon" />
          </b-avatar>
          <b-form-group
            class="flex-grow-1"
          >
            <label>Goal *</label>
            <validation-provider
              v-slot="{ errors, pristine }"
              name="Goal"
              rules="required"
            >
              <v-select
                :class="verifyGoal(errors, pristine)"
                :options="goals"
                :value="idgoal"
                label="name"
                :reduce="goal => goal.idgoal"
                @input="setGoal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center">
          <b-avatar
            variant="light-success"
            class="mr-1"
          >
            <feather-icon icon="TrendingUpIcon" />
          </b-avatar>
          <b-form-group
            class="flex-grow-1"
            label-for="exp-conversions"
            label="Expected conversions"
          >
            <validation-provider
              #default="{ errors }"
              name="Conversions"
              rules="required|positive"
            >
              <b-form-input
                id="exp-conversions"
                placeholder="Expected conversions"
                debounce="400"
                type="number"
                :value="expectedConversions"
                :state="errors.length > 0 ? false : null"
                @input="setExpectedConversions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="d-flex align-items-center">
          <b-avatar
            variant="light-info"
            class="mr-1"
          >
            <feather-icon icon="DollarSignIcon" />
          </b-avatar>
          <b-form-group
            class="flex-grow-1"
            label-for="exp-investment"
            label="Total experiment cost"
          >
            <b-form-input
              id="exp-investment"
              placeholder="Total experiment cost"
              disabled
              :value="getEditorCost"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group
          label-for="exp-start"
          label="Experiment start date *"
        >
          <validation-provider
            #default="{ errors }"
            name="Start date"
            vid="startDate"
            rules="required|regex:^-?[\d]+\-[0-1]\d\-[0-3]\d$"
          >
            <b-input-group class="mb-1">
              <b-form-input
                id="exp-start"
                autocomplete="off"
                placeholder="YYYY-MM-DD"
                debounce="800"
                :value="startDate | formatDate"
                :state="errors.length > 0 ? false : null"
                @input="setStartDate"
              />
              <b-input-group-append>
                <b-form-datepicker
                  :value="startDate"
                  right
                  button-only
                  locale="en-US"
                  size="sm"
                  @input="setStartDate"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-form-group
          label-for="exp-end"
          label="Experiment end date *"
        >
          <validation-provider
            #default="{ errors }"
            name="end date"
            rules="required|regex:^-?[\d]+\-[0-1]\d\-[0-3]\d$|after:@startDate"
          >
            <b-input-group class="mb-1">
              <b-form-input
                id="exp-end"
                autocomplete="off"
                placeholder="YYYY-MM-DD"
                debounce="800"
                :value="endDate | formatDate"
                :state="errors.length > 0 ? false : null"
                @input="setEndDate"
              />
              <b-input-group-append>
                <b-form-datepicker
                  :value="endDate"
                  right
                  button-only
                  locale="en-US"
                  size="sm"
                  @input="setEndDate"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BAvatar, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, positive, regex } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { ON_EXPERIMENT_EDITION } from '@/libs/eventBusConstants'

extend('after', {
  validate(value, { startDate }) {
    const start = new Date(startDate)
    const end = new Date(value)

    return end > start
  },
  params: ['startDate'],
  message: 'The {_field_} field must be greater than {_startDate_}',
})

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
    FeatherIcon,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.split('T')[0]
      }
      return ''
    },
  },
  data() {
    return {
      required,
      positive,
      regex,
    }
  },
  computed: {
    ...mapState('experimentEditor', {
      name: state => state.editor.name,
      description: state => state.editor.description,
      expectedConversions: state => state.editor.expectedConversions,
      investment: state => state.editor.investment,
      idgoal: state => state.editor.idgoal,
      startDate: state => state.editor.startDate,
      endDate: state => state.editor.endDate,
    }),
    ...mapState('dataFilter', {
      goals: state => state.goals,
    }),
    ...mapGetters('experimentEditor', [
      'getEditorCost',
    ]),
  },
  created() {
  },
  mounted() {
    const validate = () => new Promise((resolve, reject) => {
      this.$refs.detailForm.validate().then(valid => {
        if (valid) {
          resolve(valid)
        } else {
          reject(this.$refs.detailForm.errors)
        }
      })
    })
    this.$eventBus.$emit(ON_EXPERIMENT_EDITION, { validate })
  },
  beforeDestroy() {
    this.$eventBus.$off(ON_EXPERIMENT_EDITION)
  },
  methods: {
    ...mapActions('experimentEditor', [
      'updateExperiment',
    ]),
    setDescription(value) {
      this.updateExperiment({ description: value })
    },
    setName(value) {
      this.updateExperiment({ name: value })
    },
    setStartDate(value) {
      this.updateExperiment({ startDate: value })
    },
    setEndDate(value) {
      this.updateExperiment({ endDate: value })
    },
    setGoal(value) {
      this.updateExperiment({ idgoal: value })
    },
    setExpectedConversions(value) {
      this.updateExperiment({ expectedConversions: parseInt(value, 10) })
    },
    verifyGoal(errors, pristine) {
      const withErrors = errors.length > 0
      return {
        danger: withErrors,
        success: !withErrors && !pristine,
      }
    },
  },
}
</script>
<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';

div.danger {
  div.vs__dropdown-toggle {
    // TODO: Should import color values from variables
    border-color: #ea5455 !important;
  }
}

div.success {
  div.vs__dropdown-toggle {
    border-color: #28c76f !important;
  }
}
</style>
