<template>
  <div>
    <experiment-variants-view
      v-if="!isTableEmpty"
      class="border rounded"
      :is-edit-mode="true"
      :variants="getSavedVariants"
      :experiment-name="experimentName"
    />
    <div>
      <b-row
        v-for="variant in getEditingVariants"
        :key="variant.index"
        class="pb-2 mx-1"
      >
        <experiment-variant-editor
          :effort="variant"
          :index="variant.index"
          :experiment-name="experimentName"
        />
      </b-row>
    </div>
    <b-button
      variant="outline-primary"
      @click="addNewEffort"
    >
      Add new variant
    </b-button>
    <datalist id="effort-types-datalist">
      <option
        v-for="(type, i) in types"
        :key="i"
      >
        {{ type }}
      </option>
    </datalist>
    <datalist id="effort-sources-datalist">
      <option
        v-for="(source, i) in sources"
        :key="i"
      >
        {{ source }}
      </option>
    </datalist>
    <datalist id="effort-contents-datalist">
      <option
        v-for="(content, i) in contents"
        :key="i"
      >
        {{ content }}
      </option>
    </datalist>
  </div>
</template>

<script>
import {
  BRow,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExperimentVariantEditor from './ExperimentVariantEditor.vue'
import ExperimentVariantsView from './ExperimentVariantsView.vue'
import {
  EDITOR_NO_CHANGES,
} from '@/store/acquisition/experiment/acquisiton-constants'

export default {
  components: {
    BRow,
    BButton,
    ExperimentVariantEditor,
    ExperimentVariantsView,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      EDITOR_NO_CHANGES,

      types: [
        'Paid Campaign',
        'Influencer Marketing',
        'Affiliates',
        'Public Relations',
        'Brand partnership',
        'Social media',
        'Blog',
        'Email',
      ],
      sources: [
        'Google',
        'Search engine',
        'Newsletter',
        'Facebook',
        'Instagram',
        'Whatsapp',
        'Others',
      ],
      contents: [
        'Buy now',
        'Click here',
        'Learn more',
        'Shop now',
        'Try demo',
        'Contact us',
        'Subscribe',
        'Test group',
        'Control group',
      ],
    }
  },
  computed: {
    ...mapState('experimentEditor', {
      experimentName: state => state.editor.name,
    }),
    ...mapGetters('experimentEditor', [
      'isEditable',
      'getSavedVariants',
      'getEditingVariants',
    ]),
    isTableEmpty() {
      return this.getSavedVariants.length === 0
    },
  },
  methods: {
    ...mapActions('experimentEditor', [
      'addNewEffort',
    ]),
  },
}
</script>
