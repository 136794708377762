<template>

  <b-row
    class="flex-grow-1 d-flex border rounded bg-light "
  >
    <validation-observer
      ref="effortForm"
      slim
      class="pt-2"
    >
      <b-col>
        <b-row>
          <b-col cols="12">
            <h5 class="card-title">
              {{ isNewVariant? "New": "Update" }} experiment variant
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
          >
            <b-form-group :label-for="`effort-${index}-name`">
              <template #label>
                Variant name *
                <feather-icon
                  v-b-tooltip.hover
                  title="Marketing strategic variant name. E.g. Black Friday 2022 ADS, Black Friday 2022 Facebook."
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{ required: true, notIn: { values: getNames(index) } }"
              >
                <b-form-input
                  :id="`effort-${index}-name`"
                  placeholder="Name"
                  debounce="400"
                  :value="effort.name"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  @input="setName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
          >
            <b-form-group :label-for="`effort-${index}-type`">
              <template #label>
                Channel *
                <feather-icon
                  v-b-tooltip.hover
                  title="How are my users coming to our website? The communication channel this link will be placed."
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-input
                  :id="`effort-${index}-type`"
                  list="effort-types-datalist"
                  placeholder="Communication channel category"
                  debounce="400"
                  :value="effort.type"
                  :state="errors.length > 0 ? false : null"
                  @input="setType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
          >
            <b-form-group :label-for="`effort-${index}-cost`">
              <template #label>
                Amount to spend *
                <feather-icon
                  v-b-tooltip.hover
                  title="How much money will be spent on this marketing strategy variant?"
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>
              <validation-provider
                #default="{ errors }"
                name="Cost"
                rules="required|positive"
              >
                <b-form-input
                  :id="`effort-${index}-cost`"
                  type="number"
                  placeholder="Amount to spend"
                  :value="effort.cost"
                  :state="errors.length > 0 ? false : null"
                  @input="setCost"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
          >
            <b-form-group :label-for="`effort-${index}-source`">
              <template #label>
                Users source *
                <feather-icon
                  v-b-tooltip.hover
                  title="Where are the users coming from? E.g. Facebook, Google, Abandoned Cart Users, BBC Referals, etc."
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>
              <validation-provider
                #default="{ errors }"
                name="Source"
                rules="required"
              >
                <b-form-input
                  :id="`effort-${index}-source`"
                  list="effort-sources-datalist"
                  placeholder="Source"
                  debounce="500"
                  :value="effort.source"
                  :state="errors.length > 0 ? false : null"
                  @input="setSource"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
          >
            <b-form-group :label-for="`effort-${index}-content`">
              <template #label>
                Content (Optional)
                <feather-icon
                  v-b-tooltip.hover
                  title="Helps you differentiate between ads or posts on the same platform. Comes in handy for A/B testing images or ad copy."
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>
              <b-form-input
                :id="`effort-${index}-content`"
                list="effort-contents-datalist"
                placeholder="Content"
                debounce="500"
                :value="effort.content"
                @input="(content) => updateEffort({index, payload: { content }})"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <b-form-group
              :label-for="`effort-${index}-website`"
            >
              <template #label>
                Website to track *
                <feather-icon
                  v-b-tooltip.hover
                  title="The arrival page of the users. E.g. https://www.mycompany.com/blackfriday2020"
                  class="text-primary"
                  icon="HelpCircleIcon"
                />
              </template>

              <validation-provider
                #default="{ errors }"
                name="Website"
                rules="required|urlValidator"
              >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                  >
                    <feather-icon icon="Link2Icon" />
                  </b-input-group-prepend>

                  <b-form-input
                    :id="`effort-${index}-website`"
                    :disabled="!isNewVariant"
                    placeholder="Website"
                    debounce="500"
                    :value="effort.url"
                    :state="errors.length > 0 ? false : null"
                    @input="setUrl"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mb-1">
            <b-button
              v-if="!isNewVariant"
              variant="danger"
              class="mx-1"
              @click="cancelEditEffort(index)"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              @click="stage"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </validation-observer>

    <div class="d-flex flex-column border-left py-50 px-25">
      <feather-icon
        v-b-tooltip.hover
        size="24"
        icon="XIcon"
        role="button"
        title="Delete effort"
        class="mb-1 btn-remove-variant"
        @click="confirmRemove(index)"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, positive } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { EDITOR_ON_CREATE } from '@/store/acquisition/experiment/acquisiton-constants'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// UTM Documentation: https://cxl.com/blog/utm-parameters/

const uriValidator = urlLike => {
  try {
    return !!(new URL(urlLike))
  } catch (e) {
    return false
  }
}

const notInValidator = (input, { values = [] }) => values.every(name => name !== input)

const urlValidator = extend('urlValidator', {
  validate: uriValidator,
  message: 'Invalid URL',
})

const notIn = extend('notIn', {
  validate: notInValidator,
  params: ['values'],
  message: 'The {_field_} already exists',
})

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    effort: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    experimentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      positive,
      urlValidator,
      notIn,
    }
  },
  computed: {
    ...mapGetters('experimentEditor', [
      'getNames',
      'isEditable',
    ]),
    isNewVariant() {
      return this.effort.status === EDITOR_ON_CREATE
    },
  },
  methods: {
    ...mapActions('experimentEditor', [
      'updateEffort',
      'stageEffort',
      'cancelEditEffort',

      'removeEffort',
    ]),
    update(payload) {
      this.updateEffort({ index: this.index, payload })
    },
    stage() {
      this.$refs.effortForm.validate().then(isValid => {
        if (isValid) {
          this.stageEffort({ index: this.index })
        }
      })
    },
    setName(name) {
      this.update({ name })
    },
    setType(type) {
      this.update({ type })
    },
    setCost(value) {
      const cost = parseFloat(value) || 0
      this.update({ cost })
    },
    setUrl(urlValue) {
      this.update({ url: urlValue })
    },
    setSource(source) {
      this.update({ source })
    },
    onCopySuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Experiment URL copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    confirmRemove(index) {
      this.$swal({
        title: 'Are you sure you want to remove?',
        text: 'This is not persisted until saving the experiment.',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(response => {
        if (response.isConfirmed) {
          this.removeEffort(index)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.input-group-prepend.is-invalid .input-group-text {
    border: 1px solid #ea5455;
    border-top-left-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem;
}
.btn-remove-variant:hover {
  cursor: pointer;
  color: $danger;
}
</style>
